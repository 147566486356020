
const navigateTo = function(navigationBlock, optionName) {
  navigationBlock.find(`[navigation-option].show`).collapse('hide');
  navigationBlock.find(`[navigation-option="${optionName}"]`).collapse('show');
}

$(document).on('click', '[data-navigation-target]', function(event) {
  navigateTo($(this).closest('.navigation-block'), this.dataset.navigationTarget)
});

const navigateToOnFieldChange = function(event) {
  var optionName = this.value;
  if (optionName == null || optionName == "") { optionName = 'default' }
  navigateTo($(this).closest('.navigation-block'), optionName)
}

$(document).on('change', '.navigation-select', navigateToOnFieldChange)
$(document).on('change', '.navigation-check-box', navigateToOnFieldChange)
