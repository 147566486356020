
$(document).on('click', '[data-multiselect] .option', function(event) {

  const form = $(this).closest('form');
  var checkbox = $(this).find('input[type=checkbox]');

  if(checkbox.prop('checked')) {
    checkbox.prop('checked', false);
    $(this).find('.collapse').collapse('hide');
  } else {
    checkbox.prop('checked', true);
    $(this).find('.collapse').collapse('show');
  }

  if (form.data('selectCount') == null || form.find('input:checked').length >= parseInt(form.data('selectCount'), 10)) {
    if($(this).closest('[data-multiselect]').data('submitWithButton') == null) {
      window.Rails.fire(form[0], 'submit');
    }
  } 
});