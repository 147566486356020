
import * as Comlink from 'comlink';
import { pointsOfSightPolygon, objectSightLineStartPoints } from './board_visibility_calculations.js';

const VisibilityWorker = {
  async pointsOfSightPolygon(data) {
    return await pointsOfSightPolygon(data.point, data.wallLines, data.wallPoints);
  },

  async objectSightLineStartPoints(data) {
    return await objectSightLineStartPoints(data.object, data.wallLines);
  }
};

Comlink.expose(VisibilityWorker);
