import Popper from "popper.js";
import "fabric"
import "./jquery_import"
import "./rails_import"

window.Popper = Popper;

import "bootstrap";

// Import ActionCable channels
import "../channels";

// Import custom scripts
import "../custom/index.js"; 

// Import Trix editor and ActionText
import "trix";
import "@rails/actiontext";

// Import Stimulus controllers (assuming you're using Stimulus)
import "../controllers";

// Import Chartkick with Chart.js for visualizations
import "chartkick/chart.js";


