import { createConsumer } from "@rails/actioncable";

const consumer = createConsumer();

const channels = import.meta.glob("./**/*_channel.js", { eager: true });

Object.values(channels).forEach((module) => {
  if (typeof module.default === "function") {
    module.default(consumer);
  }
});

export default consumer;