import * as requests from "./board_requests"
import { startRulerForToken, updateAllRulerObjectsForToken } from "./board_ruler";
import { updateIndicatorObjectsPositions, allowTokenDragging } from "./board_token_drag";
import * as calculations from "./board_visibility_calculations"

var board;
var base = fabric.Canvas.prototype;
var lastValidX;
var lastValidY;
var wallLines;
var desiredX;
var desiredY;
var startRulerOnMove;


$( document ).on('board:created', function(event, b) { board = b; });

const calculateWallData = async function() {
  wallLines = [];
  board.getObjects().forEach(function(object) {
    if (object.wall == true && object.type == 'line') {
      // do not count transparent and open doors as walls for visibility 
      if(object.doorState == 'open') { return }
      var wallLine = {
          x1: object.x1,
          y1: object.y1,
          x2: object.x2,
          y2: object.y2
      };
      wallLines.push(wallLine);
    }
  });
}

const leftTopToMiddle = function(value, token) {
  return value + (token.scaleWidth / 2)
}

const middleToLeftTop = function(value, token) {
  return value - (token.scaleWidth / 2)
}

const updateBackendLocationWhenAtRest = function(currentX, currentY, token) {
  setTimeout(function() {
    // only update backend if we're still on the same col, row after a buffer time 
    if(lastValidX == currentX && lastValidY == currentY) {
      requests.updateRequest(token);
    }
  }, 200)
}

const lineFromLastPositionToNewPosition = function(lastValidX, lastValidY, desiredX, desiredY, radius) {
  // Calculate the movement vector
  const moveVector = {
    x: desiredX - lastValidX,
    y: desiredY - lastValidY,
  };

  // Calculate the magnitude of the movement vector
  const moveDistance = Math.sqrt(moveVector.x ** 2 + moveVector.y ** 2);

  // Normalize the movement vector
  const normalizedMoveVector = {
    x: moveVector.x / moveDistance || 0, // Handle divide by zero
    y: moveVector.y / moveDistance || 0,
  };

  // Add the token's radius to the end point
  const adjustedEnd = {
    x: desiredX + normalizedMoveVector.x * radius,
    y: desiredY + normalizedMoveVector.y * radius,
  };

  return {
    x1: lastValidX,
    y1: lastValidY,
    x2: adjustedEnd.x,
    y2: adjustedEnd.y,
  };
};

const calculateAdjustedTokenPosition = function(lastValidX, lastValidY, desiredX, desiredY, radius, walls) {
  let currentX = desiredX;
  let currentY = desiredY;

  // Check if the direct path intersects with any walls
  for (const wallLine of walls) {
    const movementLine = lineFromLastPositionToNewPosition(lastValidX, lastValidY, currentX, currentY, radius);
    const intersectPoint = calculations.linesIntersectAt(movementLine, wallLine);
    
    if (intersectPoint != null) {
      // Calculate wall vector
      const wallVector = {
        x: wallLine.x2 - wallLine.x1,
        y: wallLine.y2 - wallLine.y1
      };
      
      // Calculate movement vector
      const moveVector = {
        x: desiredX - lastValidX,
        y: desiredY - lastValidY
      };
      
      // Project movement onto wall
      const wallLength = Math.sqrt(wallVector.x * wallVector.x + wallVector.y * wallVector.y);
      const normalizedWall = {
        x: wallVector.x / wallLength,
        y: wallVector.y / wallLength
      };
      
      const dotProduct = moveVector.x * normalizedWall.x + moveVector.y * normalizedWall.y;
      
      // Calculate proposed sliding position
      const proposedX = lastValidX + (normalizedWall.x * dotProduct);
      const proposedY = lastValidY + (normalizedWall.y * dotProduct);
      
      // Check if sliding position intersects with any other walls
      let canSlide = true;
      for (const otherWall of walls) {
        if (otherWall === wallLine) continue;
        
        const slideMovement = lineFromLastPositionToNewPosition(lastValidX, lastValidY, proposedX, proposedY, radius);
        if (calculations.linesIntersectAt(slideMovement, otherWall) != null) {
          canSlide = false;
          break;
        }
      }
      
      if (canSlide) {
        currentX = proposedX;
        currentY = proposedY;
      } else {
        // If we can't slide, stay at last valid position
        currentX = lastValidX;
        currentY = lastValidY;
      }
      break;
    }
  }

  return { x: currentX, y: currentY };
}

const setLastValidPosition = function(token) {
  lastValidX = leftTopToMiddle(token.left, token);
  lastValidY = leftTopToMiddle(token.top, token);
}

const tokensCanMoveThroughWalls = function() {
  return board.currentUserIsGm && board.gmDragTokensThroughWalls && !board.isWallingMode
}

base.handleTokenDragSmooth = function(options) {
  var token = options.target;
  if (!allowTokenDragging(token.uid)) {
    token.set({ left: middleToLeftTop(lastValidX, token), top: middleToLeftTop(lastValidY, token) });
    return;
  }
  board.selectCharacterOnMouseInteraction(options);
  board.isDraggingObject = true;
  if (!tokensCanMoveThroughWalls()) {
    desiredX = leftTopToMiddle(token.left, token);
    desiredY = leftTopToMiddle(token.top, token);
    const adjustedPosition = calculateAdjustedTokenPosition(lastValidX, lastValidY, desiredX, desiredY, token.scaleWidth / 2, wallLines);
    token.set({ left: middleToLeftTop(adjustedPosition.x, token), top: middleToLeftTop(adjustedPosition.y, token) }); 
  } 
  setLastValidPosition(token);
  updateIndicatorObjectsPositions(token);
  if (startRulerOnMove != null) {
    startRulerForToken(startRulerOnMove);
    startRulerOnMove = null;
  }
  if (board.rulerWhileMoving) {
    updateAllRulerObjectsForToken(leftTopToMiddle(token.left, token), leftTopToMiddle(token.top, token), token);
  }
  updateBackendLocationWhenAtRest(leftTopToMiddle(token.left, token), leftTopToMiddle(token.top, token), token);
  board.updateVisibility();
};

base.handleMouseDownForTokenDragSmooth = function(options) {
  setLastValidPosition(options.target);
  if (!allowTokenDragging(options.target.uid)) { return }
  if (!tokensCanMoveThroughWalls()) { calculateWallData() }
  if (board.rulerWhileMoving) {
    startRulerOnMove = options;
  }
}