import * as requests from "./board_requests"
import * as moveToken from "./board_token_drag_snap"

import { setAllOpacityAndSelectability } from "./board_object_opacity_and_selectability";
import { updateCursorIcon } from "../board_cursor";
import { toggleDisplayOption } from "./board_display_options";
import { startNav } from "../navigation/slide_nav";
import { handleAjaxResponse } from "../handle_ajax_response";
import { Modal } from 'bootstrap';
import { throttle } from "../throttle";

const leftArrowKey = 37,
      rightArrowKey = 39,
      upArrowKey = 38,
      downArrowKey = 40;
const arrowKeys = [leftArrowKey, rightArrowKey, upArrowKey, downArrowKey];

export const setBoardTool = function(toolId) {

  var toolSelect = $('.tool-select');
  var board = window.__board;

  if(toolId == 'tool-draw') {
    board.isDrawingMode = true;
  } else {
    board.isDrawingMode = false;
  }
  if(toolId == 'tool-walls') {
    board.startWallingMode();
  } else {
    board.endWallingMode();
  }
  if(toolId == 'tool-eraser') {
    board.isErasingMode = true;
  } else {
    board.isErasingMode = false;
  }
  if(toolId == 'tool-light') {
    board.isLightingMode = true;
  } else {
    board.isLightingMode = false;
  }
  if(toolId == 'tool-pins') {
    board.isPinningMode = true;
  } else {
    board.isPinningMode = false;
  }
  if(toolId == 'tool-ruler') {
    board.isRulerMode = true;
  } else {
    board.isRulerMode = false;
  }

  

  toolSelect.removeClass('open');
  toolSelect.find('.action').removeClass('selected text-highlight');
  $(`[data-tool-id=${toolId}]`).addClass('selected text-highlight');
  // just for mobile, where options are collapsible
  toolSelect.find('.action.collapse').not('.selected').collapse('hide');

  updateCursorIcon();
  setAllOpacityAndSelectability(board);

  // discard all selections in case something selected in one tool is no longer selectable
  board.discardActiveObject();
  board.renderAll();
}

function openMenuLink(selector) {
  if(selector == 'toggle-combat-hotkey') {
    var startCombatLink = $('.toggle-combat-hotkey');
    $.ajax({
      url: `${startCombatLink.attr('href')}`,
      type: startCombatLink.data('method'),
      success: function (data) {
        handleAjaxResponse(data, $(this));
      },
    });
  } else {
    startNav($(`.${selector}`).data('startNav'))
  }
}

var board;
$( document ).on('board_id:set', function(event, b) { 
  board = b;

  // handle zoom controls
  let zoomIn = $('#zoom-in')[0];
  let zoomOut = $('#zoom-out')[0];
  zoomIn.onclick = function() {
    var board = window.__board;
    var zoomTo = board.getZoom() * 1.25;
    board.usefulZoom(zoomTo);
  };
  zoomOut.onclick = function() {
    var board = window.__board;
    var zoomTo = board.getZoom() * 0.75;
    board.usefulZoom(zoomTo);
  };

  // handle main menu
  $('#main-menu').on('mouseleave', function () {
    $('#main-menu.show .dropdown-toggle').dropdown('toggle');
  })

  // just for mobile, where options are collapsible
  $(document).on('click', '.tool-select:not(.open)', function (e) {
    $(this).find('.action.collapse').collapse('show');
    setTimeout(function() {
      $('.tool-select').addClass('open');  
    }, 450)
  })

  // handle tool controls
  $(document).on('click','.tool-select [data-tool-id]' , function() {

    var toolId = $(this).data()['toolId'];
    setBoardTool(toolId);

  });

})

// track keyboard input 
const pressedKeys = new Set();
var hotkeysModal;
const throttledOpenMenuLink = throttle(openMenuLink, 1000, 1);

$('html').on('keydown' , function(event) {
  pressedKeys.add(event.key);
  var board = window.__board;
  if(board == null) { return }
  if($(event.target).is('input') || $(event.target).is('textarea') || $(event.target).hasClass('trix-content') || $(event.target).hasClass('dropdown-item') || $(event.target).data('toggle') == 'dropdown'){ return }

  if (pressedKeys.size === 1) {
    if(event.which == 8 || event.which == 46) {
      if(board.tokenSelected()) {
        requests.destroyRequest([board.selectedCharacterId]); 
        board.removeObject(board.selectedToken());
      }
      const activeObject = board.getActiveObject();
      if(board.currentUserIsGm && activeObject != null && ((activeObject.type == 'image' && activeObject.background != true) || activeObject.shapeType != null)) {
        requests.destroyRequest([activeObject.uid]); 
        board.removeObject(activeObject);
      }
      return false;
    }
    if(document.getElementById('hotkeys-enabled') != null) {
      if (!board.overview) {
        if (event.key === 'h') {
          setBoardTool('tool-draw');
          return false;
        } else if (event.key === 'm') {
          setBoardTool('tool-move');
          return false;
        } else if (event.key === 'r') {
          setBoardTool('tool-ruler');
          return false;
        } else if (board.currentUserIsGm && event.key === 'p') {
          setBoardTool('tool-pins');
          return false;
        } else if (board.currentUserIsGm && event.key === 'e') {
          setBoardTool('tool-eraser');
          return false;
        } else if (board.currentUserIsGm && event.key === 'w') {
          setBoardTool('tool-walls');
          return false;
        } else if (board.currentUserIsGm && event.key === 'l') {
          setBoardTool('tool-light');
          return false;
        } else if (board.currentUserIsGm && event.key == 'g') {
          toggleDisplayOption('gmLayerVisible');
          return false;
        } else if (board.currentUserIsGm && event.key == 'v') {
          toggleDisplayOption('visibilityVisible');
          return false;
        } else if (board.currentUserIsGm && event.key == 't') {
          throttledOpenMenuLink('add-token-hotkey');
          return false;
        } else if (event.key == 'i') {
          throttledOpenMenuLink('add-images-hotkey');
          return false;
        } else if (board.currentUserIsGm && event.key == 'd') {
          throttledOpenMenuLink('map-details-hotkey');
          return false;
        }
      } 
      if (board.currentUserIsGm && event.key == 's') {
        throttledOpenMenuLink('switch-map-hotkey');
        return false;
      } else if (event.key == 'a') {
        throttledOpenMenuLink('assistant-hotkey');
        return false;
      } else if (event.key == 'c') {
        throttledOpenMenuLink('characters-hotkey');
        return false;
      } else if (event.key == 'z') {
        throttledOpenMenuLink('compendium-hotkey');
        return false;
      } else if (event.key == 'y') {
        throttledOpenMenuLink('players-hotkey');
        return false;
      } else if (event.key == 'n') {
        throttledOpenMenuLink('notes-hotkey');
        return false;
      } else if (board.currentUserIsGm && event.key == 'x') {
        throttledOpenMenuLink('toggle-combat-hotkey');
        return false;
      } else if (event.key == 'k') {
        var modalElement;
        if(board.currentUserIsGm) {
          modalElement = document.getElementById('hotkeys-modal-gm');
        } else {
          modalElement = document.getElementById('hotkeys-modal-player');
        }
        if($(modalElement).hasClass('show')) {
          hotkeysModal.hide();
        } else {
          hotkeysModal = new Modal(modalElement)  
          hotkeysModal.show();
        } 
      }        
    }
    if (board.tokenSelected()) {
      var token = board.selectedToken();
      board.storeStartValuesForTokenDragSnap(token);
      if(arrowKeys.includes(event.which)) {

        if(event.which == upArrowKey) {
          moveToken.moveUp();
        } else if (event.which == downArrowKey) {
          moveToken.moveDown();
        } else if (event.which == leftArrowKey) {
          moveToken.moveLeft();
        } else if (event.which == rightArrowKey) {
          moveToken.moveRight();
        } 
        moveToken.updateTokenLocationSnap();
        return false;
      }
    }
  }
});


$('html').on('keyup' , function(event) {
  pressedKeys.delete(event.key)
});

function handleWindowChange() {
  pressedKeys.clear();
}

window.addEventListener('blur', handleWindowChange);
window.addEventListener('focus', handleWindowChange);