var lastZoom = null;
let initialDistance = null;

const getDistance = (touch1, touch2) => {
  const dx = touch2.clientX - touch1.clientX;
  const dy = touch2.clientY - touch1.clientY;
  return Math.sqrt(dx * dx + dy * dy);
};

const pinchStartHandler = function(e) {
  if (e.touches.length < 2) return;
  const [touch1, touch2] = e.touches;
  initialDistance = getDistance(touch1, touch2);

  lastZoom = window.__board.getZoom();
  window.__board.isDragging = false;
  window.__board.isPinching = true;
};

const pinchMoveHandler = function(e) {
  try {
    if (e.touches.length < 2) return;

    var board = window.__board;

    let scale;
    if (e.scale !== undefined) {
      // Use scale for iOS
      scale = e.scale;
    } else {
      // Calculate scale for Android
      const [touch1, touch2] = e.touches;
      const currentDistance = getDistance(touch1, touch2);
      scale = currentDistance / initialDistance;
    }

    var zoomTo = lastZoom * scale;
    board.usefulZoom(zoomTo);
    board.isDragging = false;
    board.isPinching = true;
    e.preventDefault();
    e.stopPropagation();
  } catch (error) {
    console.error('Pinch Move Error:', error);
  }
};


$( document ).on('campaign_id:set', function() {

  const main = document.getElementById('main');
  lastZoom = window.__board.getZoom();
  // Attach the handler
  main.removeEventListener('touchmove', pinchMoveHandler);
  main.removeEventListener('touchstart', pinchStartHandler);
  main.addEventListener('touchmove', pinchMoveHandler);  
  main.addEventListener('touchstart', pinchStartHandler);  
})
