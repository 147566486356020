
var board;
var base = fabric.Canvas.prototype;

$( document ).on('board:created', function(event, b) { board = b; });

export const updateIndicatorObjectsPositions = function(token) {
  if(token.indicatorObjects != null) { 
    token.indicatorObjects.forEach(function(indicatorObject) {
      indicatorObject.set({ top: token.top + indicatorObject.offsetTop, left: token.left + indicatorObject.offsetLeft })
    })
  }
  if (token.combatIndicatorObject != null) {
    token.combatIndicatorObject.set({ top: token.top + token.combatIndicatorObject.offsetTop, left: token.left + token.combatIndicatorObject.offsetLeft })
    token.combatIndicatorObjectBackground.set({ top: token.top + token.combatIndicatorObject.offsetTop, left: token.left + token.combatIndicatorObject.offsetLeft })
  }
}

export const allowTokenDragging = function(uid) {
  if (board.isDrawingMode) return false;
  if (board.isRulerMode) return false;
  return board.isSelectableCharacterId(uid);
}

base.handleTokenDrag = function(options) {
  if(options.target == null || !options.target.token ) { return }
  if(board.snapToGrid){
    board.handleTokenDragSnap(options);
  } else {
    board.handleTokenDragSmooth(options);
  }
}


base.handleMouseDownForTokenDrag = function(options) {
  if(options.target == null || !options.target.token ) { return }
  if(board.snapToGrid){
    board.handleMouseDownForTokenDragSnap(options);
  } else {
    board.handleMouseDownForTokenDragSmooth(options);
  }
}
